import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const style = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [50]
  })
)

export const ContentBps = css(
  mq({
    width: [328, '100%', '100%', '100%'],
    marginLeft: [0, 0, 200, 233],
    marginRight: [0, 0, 200, 238],
    textAlign: ['left']
  })
)

export const contentPost = css`
  h2 {
    font-size: 23.7px;
    font-weight: 500;
  }
  h2:not(:last-child) {
    margin-bottom: 16px;
  }
  h4 {
    font-size: 20px;
  }
  h4:not(:last-child) {
    margin-bottom: 16px;
  }
  p {
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.5;
    color: rgba(0, 0, 0, 0.87);
  }
  p:not(:last-child) {
    margin-bottom: 24px;
  }
  strong {
    font-weight: 500;
  }
  em {
    font-style: italic;
  }
  img {
    max-width: 100%;
    width: 100%;
  }
  ul {
    list-style-type: square;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.5;
    color: rgba(0, 0, 0, 0.87);
    padding-left: 40px;
    margin-bottom: 24px;
    li:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  figure {
    margin-bottom: 24px;
    width: 100%;
    max-width: 100%;
    div {
      width: 100% !important;
    }
  }
  iframe {
    width: 100%;
  }
`

export const dots = css`
  width: 4px;
  height: 4px;
  background-color: #44474e;
  border-radius: 50%;
  :not(:last-child) {
    margin-right: 30px;
  }
`

export const relatedPost = css`
  .row:first-child(1) {
    margin-bottom: 40px;
  }
`