import React from 'react'
import PropTypes from 'prop-types' 
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Article from '../components/Article'

import H4 from '../components/H4'
import Div from '../components/Div/Div.styles'
import Paragraph from '../components/Paragraph'
import Button from '../components/Button/Button'
import Hr from '../components/Hr'
import {
  contentPost
} from '../components/styles/PostBlog.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section>
      <Div
        px={[16, 16, 24, 24]}
        alignItems={['center']}
      >
        <Div
          maxWidth={['100%', 390, 600]}
          alignItems={['center', 'center', 'stretch']}
        >
          <Row
            pt={[50]}
            pb={[16]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Div
                flexDirection='column'
                alignItems='center'
                justifyContent='left'
                maxWidth={['100%', 344, '100%']}
              >
                <Div
                  style={{
                    fontSize: 11.8,
                    fontWeight: 600,
                    lineHeight: 1.36,
                    letterSpacing: 2,
                    color: 'rgba(0, 0, 0, 0.6)',
                    textTransform: 'uppercase',
                    marginBottom: 8
                  }}
                >
                  12 Fev 2019 • Leitura 5 min
                </Div>
                <H4
                  selected
                  on='onSurface'
                  variant='highEmphasis'
                >
                  Dia da Internet Segura: saiba como se proteger na web
                </H4>
              </Div>
            </Column>
          </Row>
          <Row
            flexDirection={['column']}
            css={contentPost}
          >
            <Column
              px={[null]}
            >
              <Div
                style={{
                  fontSize: 15.8,
                  fontWeight: 500,
                  lineHeight: 1.77,
                  letterSpacing: 0.5,
                  color: 'rgba(0, 0, 0, 0.6)'
                }}
              >
                Muito além do preço oferecido pelo mercado, saiba quais são as especificações que você precisa levar em conta no momento da sua escolha e que vão influenciar a experiência do usuário final.
              </Div>
            </Column>
            <Column
              mt={[30]}
              mb={[50]}
              px={[null]}
            >
              <Paragraph>Quando você está buscando um servidor dedicado, também conhecido como bare metal, para a sua empresa, obviamente o valor oferecido é um fator que vai ser levado em consideração até o momento de bater o martelo e efetivamente levar um para casa.</Paragraph>
              <Paragraph>Só que o preço está longe de ser a única coisa que você deve observar. Ao focar unicamente no fator econômico em detrimento das especificações da máquina, você pode ter que desembolsar bem mais do que pretendia, principalmente levando em consideração o crescimento contínuo do ambiente que a sua empresa pode ter.</Paragraph>
              <Paragraph>Como uma forma simples de comparação, vamos utilizar dois processadores da mesma marca, mas com versões diferentes: o Xeon E3-1240 v2 e o Xeon E3-1230 v5. Ambos os processadores são da Intel e costumam ser utilizados por servidores de data center, sendo o primeiro uma versão mais antiga e encontrada por valores mais baixos no mercado.</Paragraph>
              <Paragraph>Todos os pontos aqui levantados levam a uma questão crucial: o nível de exigência de performance. Naturalmente, um servidor com especificações menos robustas vai ter um desempenho menor, mas ele pode ser bem interessante para que não precisa de tanta potência computacional e conhece as especificações que o produto oferece.</Paragraph>
              <Paragraph>Se for o caso da sua empresa, você pode levar em consideração os servidores vendidos no sistema outlet, por exemplo. Por terem configurações menos avançadas e alguns contarem com sistemas operacionais já instalados, eles são ofertados com preços mais baixos que os comumente praticados no mercado.</Paragraph>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0 0 40px']}
          maxWidth={['100%', 422, 728, 813]}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 813]}
          alignItems={['center', 'center', 'stretch']}
        >
          <Row
            pb={[50, 70]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Div
                flexDirection='row'
                alignItems='center'
                justifyContent='left'
                maxWidth={['100%', 344, '100%']}
              >
                <H4
                  selected
                  on='onSurface'
                  variant='highEmphasis'
                >
                  Posts Relacionados
                </H4>
              </Div>
              <Row
                flexDirection={['column', 'column', 'row']}
                mt={[40]}
                mb={[50]}
                maxWidth={['100%', 344, '100%']}
              >
                <Column
                  width={[1, 1, 1/2]}
                  mb={[50, 50, 0]}
                >
                  <Article
                    title='Quais mudanças a LGPD trará para o setor da saúde?'
                    meta={{
                      date: '22 Mar 2019',
                      duration: '6 min'
                    }}
                  />
                  <Article
                    title='Quais mudanças a LGPD trará para o setor da saúde?'
                    meta={{
                      date: '22 Mar 2019',
                      duration: '6 min'
                    }}
                  />
                </Column>
                <Column
                  width={[1, 1, 1/2]}
                >
                  <Article
                    title='Quais mudanças a LGPD trará para o setor da saúde?'
                    meta={{
                      date: '22 Mar 2019',
                      duration: '6 min'
                    }}
                  />
                  <Article
                    title='Quais mudanças a LGPD trará para o setor da saúde?'
                    meta={{
                      date: '22 Mar 2019',
                      duration: '6 min'
                    }}
                  />
                </Column>
              </Row>
              <Button
                schema='white'
                outlined
                selected
                variant='base'
                on='onSurface'
                width={['100%']}
                maxWidth={['200px']}
                style={{
                  display: 'flex',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  margin: '0 auto'
                }}
              >
                Carregar mais
              </Button>
            </Column>
          </Row>
        </Div>
      </Div>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Blog' />
      <PageTemplate
        title='Blog'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
    }
    image1: file(relativePath: { eq: "blog.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
